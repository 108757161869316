import { render, staticRenderFns } from "./ReferenceField.vue?vue&type=template&id=7daa5d60"
import script from "./ReferenceField.vue?vue&type=script&lang=js"
export * from "./ReferenceField.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NuxtImg: require('/codebuild/output/src1025155862/src/client/components/NuxtImg.vue').default,MPowerValidateInput: require('/codebuild/output/src1025155862/src/client/components/MPowerValidateInput.vue').default,UploadModalButton: require('/codebuild/output/src1025155862/src/client/components/uploadButton/UploadModalButton.vue').default})
